import axios from 'axios';
import {
  LoginWithToken,
  LoginWithPassword,
  CreateBrokerResponse,
  CreateBroker,
  UpdateAccount,
  SetPassword,
  ForgotPassword,
  CurrentUserResponse,
  CreateReferral,
  CreateBudgetInPrinciple,
  CreateBudgetInPrincipleResponse,
  ReferralBreakdown,
  BrokerReferralsList,
} from './apiTypes';
import FileDownload from 'js-file-download';

const axiosInstance = axios.create({
  // baseURL: publicRuntimeConfig.NEXT_GATEWAY_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// API returns 200 with "$success: false" instead of http 404 code for
// resources that are not found (feature request from the client), so we
// want to manually fail requests on client side when we see failed request.
axiosInstance.interceptors.response.use((res) => {
  // if (res.data.$success === false) {
  //   throw new Error('Request failed');
  // }

  return res;
});

axiosInstance.interceptors.request.use((req) => {
  // if (res.data.$success === false) {
  //   throw new Error('Request failed');
  // }
  const token = localStorage.getItem('token');
  if (token && req.headers) {
    req.headers['Authorization'] = token;
  }
  return req;
});

export const createBroker = (
  data: CreateBroker,
): Promise<CreateBrokerResponse> =>
  axiosInstance.post('/api/brokers', data.broker).then((res) => res.data);

export const getCurrentUser = (): Promise<CurrentUserResponse> =>
  axiosInstance.get(`/auth/me`).then((res) => res.data);

export const createReferral = (
  data: CreateReferral,
): Promise<CreateBrokerResponse> => {
  const url = `/api/brokers/${data.brokerId}/referrals`;
  return axiosInstance.post(url, data.client).then((res) => res.data);
};

export const getBrokerById = async (
  url: string,
): Promise<CreateBrokerResponse> => {
  const res = await axiosInstance
    .get('/api/brokers/me', {})
    .then((res) => res.data);
  return res;
};

export const getReferralBreakdown = async (
  brokerId: string,
): Promise<ReferralBreakdown> => {
  const res = await axiosInstance
    .get(`/api/brokers/${brokerId}/referral-breakdown`, {})
    .then((res) => res.data);
  return res;
};

export const getPDF = async (
  token: string,
  applicationId: string,
  applicationName: string,
): Promise<any> => {
  const res = await axiosInstance
    .get(`/api/pdf?token=${token}&applicationId=${applicationId}&pdfType=bip-pdf`, {
      responseType: 'blob',
    })
    .then((res) => {
      FileDownload(
        res.data,
        'Keyzy Budget in Principle - ' + applicationName + '.pdf',
        'application/pdf',
      );
    });
};

export const getBrokerReferrals = async (
  brokerId: string,
): Promise<BrokerReferralsList> => {
  const res = await axiosInstance
    .get(`/api/brokers/${brokerId}/referrals`, {})
    .then((res) => res.data);
  return res;
};

export const updateAccount = ({
  brokerId,
  data,
}: UpdateAccount): Promise<any> =>
  axiosInstance.put(`/api/brokers/${brokerId}`, data).then((res) => res.data);

export const createPassword = (data: SetPassword): Promise<any> =>
  axiosInstance.post('/api/set-password', data).then((res) => res.data);

export const forgotPassword = (data: ForgotPassword): Promise<any> =>
  axiosInstance.post('/api/forgot-password', data).then((res) => res.data);

export const loginWithToken = async (data: LoginWithToken): Promise<any> => {
  !!data.actionToken && localStorage.setItem('token', data.actionToken);
  const res = await axiosInstance
    .post('/api/verify', {})
    .then((res) => res.data);
  return res;
};

export const loginWithPassword = async (
  data: LoginWithPassword,
): Promise<string> => {
  try {
    const res = await axiosInstance.post('/api/login', data);
    !!res.data && localStorage.setItem('token', res.data);
    return res.data;
  } catch (err) {
    throw new Error('We don’t recognise either the email address or password. Please try again or reset your password.');
  }
};

export const createBudgetInPrinciple = async (
  data: CreateBudgetInPrinciple,
): Promise<CreateBudgetInPrincipleResponse | any> =>
  await axiosInstance
    .post(`/api/brokers/${data.brokerId}/create-application`, data.data)
    .then((res) => res)
    .catch((err) => err.response);

export const logout = (): Promise<void> => axiosInstance.delete('/api/logout');

export default axiosInstance;
