import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';

import Button from '@/atoms/Button';
import Input from '@/atoms/Input';
import RightArrow from '@/public/icons/arrow-right.svg';
import Key from '@/public/icons/key.svg';
import CheckBlueIcon from '@/public/icons/check-bg-blue-md.svg';

import { loginWithPassword } from '@/services/api';

import routes from '@/constants/routes';
import { LoginWithPassword } from '@/services/apiTypes';
import { useForm } from 'react-hook-form';
import { loginSchema } from '@/helpers/validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthContext } from '@/contexts/Auth';
import Link from 'next/link';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const Login = () => {
  const router = useRouter();
  const { isAuth } = useAuthContext();
  const [passwordType, setPasswordType] = useState<'text' | 'password'>(
    'password',
  );

  const [isEmpty, setIsEmpty] = useState(false);
  const togglePasswordType = () =>
    setPasswordType(passwordType === 'text' ? 'password' : 'text');

  const onSubmit = async (data: LoginWithPassword) => {
    try {
      await loginWithPassword(data);
      router.push(routes.dashboard.root);
    } catch (err: any) {
      setError('password', { message: err?.message || 'General error' });
    }
  };

  const {
    handleSubmit,
    formState: { errors, touchedFields },
    setError,
    register,
  } = useForm<LoginWithPassword>({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    isAuth && router.push(routes.dashboard.root);
  }, [isAuth, router]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col max-w-2xl mx-auto">
          <h1 className="flex flex-col mb-3 text-3xl font-bold md:flex-row md:text-4xl md:mb-16 md:mt-[3rem]">
            <Key width={64} height={64} alt="log in"  />
            <div className='flex  flex-col mt-[1.125rem] md:mt-0 ml-0 md:ml-3'>
              <div className="pt-3">Hello there, please log in</div>
              
              
              <div className="font-serif text-3xl break-words max-w-[52.75rem] mx-auto font-normal">
                View your portal to create and track referrals
              </div>
            </div>
          </h1>

          <Link
            href={routes.home.root}
          >
            <a
              className="relative flex items-center mt-[1.375rem] md:mt-0 mb-4 md:mb-6 pl-4 md:pl-0"

              href={routes.home.root}
            >
              <span
                className={`md:inline-block relative text-lg font-normal text-landing-orange md:mr-2`}
              >
                Need an account? Sign up here
              </span>
              <RightArrow className="ml-1" />
            </a>
          </Link>
          <Link
            href={`${publicRuntimeConfig.NEXT_CUSTOMER_APP}/dashboard/login`}
          >
            <a
              className="relative flex items-center mb-4 md:mb-6 lg:mb-9 pl-4 md:pl-0"
              href={`${publicRuntimeConfig.NEXT_CUSTOMER_APP}/dashboard/login`}
            >
              <span
                className={`md:inline-block relative text-lg font-normal text-landing-orange md:mr-2`}
              >
                Keyzy Customer login
              </span>
              <RightArrow className="ml-1" />
            </a>
          </Link>

          <div className="w-full px-4 md:px-12 py-8 md:py-10 pb-4 md:pb-4 mx-auto mb-0 md:mb-16 bg-white shadow-3xl">
            <Input
              type="text"
              label="Email address"
              placeholder="e.g. julie.smith@mail.com"
              iconPosition="right-6 top-6"
              {...register('email', { required: true })}
              error={
                touchedFields.email && errors.email ? errors.email.message : ''
              }
              onKeyDown={() => setIsEmpty(true)}
              suffix={!errors.email && isEmpty ? <CheckBlueIcon /> : ''}
            />

            <Input
              type={passwordType}
              label="Password"
              placeholder="your password"
              {...register('password', { required: true })}
              error={
                touchedFields.password && errors.password
                  ? errors.password.message
                  : ''
              }
              suffix={
                <Image
                  src={
                    passwordType === 'password'
                      ? '/icons/eye.svg'
                      : '/icons/eye-crossed.svg'
                  }
                  width={32}
                  height={32}
                  alt="show password"
                  className="cursor-pointer"
                  onClick={togglePasswordType}
                />
              }
            />
          </div>

          <div className="flex flex-row-reverse flex-wrap justify-between sm:flex-nowrap mt-10 md:mt-0">
            <Button
              type="submit"
              suffix={<RightArrow className="ml-3 stroke-brown-200" />}
            >
              Log in
            </Button>
            <Button
              variant="outline"
              type="button"
              onClick={() => router.push(routes.login.forgotPassword)}
            >
              Forgot password?
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
