import { noop } from '@/helpers/misc';
import React, {
  createContext,
  ReactNode,
  useContext,
  FC,
  useState,
  useEffect,
} from 'react';

import { useRouter } from 'next/router';
import jwtDecode from 'jwt-decode';
import { loginWithToken, getBrokerById } from '@/services/api';
import routes from '@/constants/routes';

type ContextValue = {
  user: any;
  userId: string;
  getUserDetails: () => void;
  isAuth: boolean;
};

export const AuthContext = createContext<ContextValue>({
  user: {},
  userId: '',
  getUserDetails: noop,
  isAuth: false,
});

interface AuthProviderProps {
  children?: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState('');
  const [isAuth, setIsAuth] = useState(false);
  const router = useRouter();
  const { token } = router.query;

  const getUserDetails = async () => {
    const localToken = localStorage.getItem('token');
    const mainToken = (!!token && token) || localToken || '';
    const { user_id }: any = jwtDecode(mainToken.toString());
    await getBrokerById(user_id).then((user: any) => setUser(user));
  };

  useEffect(() => {
    const localToken = localStorage.getItem('token');
    const mainToken = (!!token && token) || localToken || '';

    const tryLoginWithToken = async () => {
      if (!!mainToken)
        try {
          const user = await loginWithToken({
            actionToken: mainToken.toString(),
          });

          if (user === '') {
            const { user_id }: any = jwtDecode(mainToken.toString());
            await getBrokerById(user_id).then((user: any) => setUser(user));
            setUserId(user_id);
            setIsAuth(true);
          } else {
            localStorage.removeItem('token');
            setUserId('');
            setUser({});
            setIsAuth(false);
            if (!router.pathname.startsWith('/sign-up'))
              router.push(routes.login.root);
          }
        } catch (err) {
          localStorage.removeItem('token');
          setIsAuth(false);
          if (!router.pathname.startsWith('/sign-up'))
            router.push(routes.login.root);
        }
    };
    if (!router.isReady) return;
    if (!!mainToken && router.isReady) {
      tryLoginWithToken();
    } else {
      setUserId('');
      setIsAuth(false);
      setUser({});
      router.push(routes.root);
    }
  }, [token, loginWithToken, router.isReady]);

  const contextConfig: ContextValue = {
    userId,
    user,
    isAuth,
    getUserDetails,
  };

  return (
    <AuthContext.Provider value={contextConfig}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthContext() {
  return useContext(AuthContext);
}

export default AuthProvider;
