import Head from 'next/head';
import Login from '@/components/applicationForm/Login';
import ApplicationLayout from '@/containers/ApplicationFormLayout';
import AuthenticationProvider from '@/contexts/Auth';
import type { ReactElement } from 'react';

const LoginPage = () => {
  return (
    <>
      <Head>
        <title>Keyzy’s Intermediaries Log in</title>
      </Head>
      <Login />
    </>
  );
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return (
    <ApplicationLayout noAccount>
      <AuthenticationProvider>{page}</AuthenticationProvider>
    </ApplicationLayout>
  );
};

export default LoginPage;
